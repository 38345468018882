// extracted by mini-css-extract-plugin
export var blush = "bx_d2";
export var blushIllustration = "bx_dM";
export var button = "bx_bt";
export var cardHeadline = "bx_dN";
export var cardRoot = "bx_dL";
export var cardSubtitle = "bx_d1";
export var resourcesTileMobileVerticalContainer = "bx_d0";
export var rowTwo = "bx_dZ";
export var rowtwosignal = "bx_d3";
export var wrapper = "bx_br";