// extracted by mini-css-extract-plugin
export var applyBtn = "bF_dG";
export var menuItemClosed = "bF_fq";
export var menuItemOpened = "bF_fn";
export var navClosed = "bF_fm";
export var navContainer = "bF_fk";
export var navOpened = "bF_fl";
export var submenuClosed = "bF_ft";
export var submenuIcon = "bF_fp";
export var submenuItem = "bF_fr";
export var submenuOpened = "bF_fs";